import Icon, { AmenityIcons } from '@dx-ui/osc-icon';
import cx from 'classnames';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { useTheme } from '../../hooks/use-theme';

import type { IconGridProps } from './icon-grid-types';

const IconGrid = function ({ brandCode, gridItems, title }: IconGridProps) {
  const { isTailored } = useGlobalConfig();
  const { theme } = useTheme();

  return gridItems && gridItems.length > 0 ? (
    <div className="p-4 pb-0 lg:p-0" data-testid="icon-grid-container">
      {!isTailored ? (
        <h2
          className={cx('heading-2xl lg:heading-3xl pb-6 text-center md:pb-8 lg:text-start', {
            'accent-heading': theme.accent?.heading,
          })}
          data-testid="icon-grid-header"
        >
          {title}
        </h2>
      ) : null}
      <div className="flex justify-center">
        <ul
          className={cx('grid gap-x-10 sm:gap-x-20 md:gap-x-8 lg:gap-x-0', {
            'grid-cols-1 md:grid-cols-2 xl:grid-cols-3': isTailored,
            'grid-cols-2 md:grid-cols-3': !isTailored,
          })}
        >
          {gridItems.map((item, index) => {
            const amenityIconName =
              brandCode === 'PE' && item.id === 'freeBreakfast' ? 'bagel' : AmenityIcons[item.id];
            return (
              <li
                key={item.id}
                className={cx('flex pb-6 md:pb-8', {
                  'gap-3 md:gap-6': isTailored,
                  'aspect-w-1 aspect-h-1 max-w-xs flex-col items-center md:items-start justify-center sm:flex-row sm:justify-start sm:gap-3':
                    !isTailored,
                })}
                data-testid={`grid-item-${index + 1}`}
                aria-label={item.name}
              >
                <Icon
                  name={amenityIconName}
                  className={theme.accent?.icon ? 'accent-icon' : undefined}
                />
                <p
                  data-testid={`grid-item-label-${index + 1}`}
                  className="text-center text-base md:pe-4 md:text-start xl:text-lg"
                >
                  {item.name}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default IconGrid;
export { IconGrid };
