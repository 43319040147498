import classnames from 'classnames';

import { useTheme } from '../../../hooks/use-theme';

import type { DescriptionAmenitiesProps } from '../../../components/description-amenities/description-amenities';

function HotelDescription(props: Pick<DescriptionAmenitiesProps, 'headline' | 'shortDesc'>) {
  return (
    <div className="border-border-alt container border-b pb-4 text-center md:pe-8 lg:border-b-0 lg:border-e lg:pb-0 lg:text-start">
      <Headline>{props.headline}</Headline>
      <Description className="mx-auto max-w-screen-md lg:mx-0">{props.shortDesc}</Description>
    </div>
  );
}

export function Headline({ children, className, ...rest }: React.ComponentProps<'h2'>) {
  const { theme } = useTheme();

  return (
    <h2
      {...rest}
      className={classnames(
        'heading-2xl lg:heading-3xl pb-6 lg:pb-8',
        {
          'accent-heading': theme.accent?.heading,
        },
        className
      )}
    >
      {children}
    </h2>
  );
}

export function Description({ children, className, ...rest }: React.ComponentProps<'p'>) {
  return (
    <p {...rest} className={classnames('w-full text-pretty text-base lg:text-lg', className)}>
      {children}
    </p>
  );
}

export default HotelDescription;
