import { useEffect, useRef, useState } from 'react';
import { meetsContrastGuidelines, rgbToColorString } from 'polished';
import {
  BrandButton as BaseBrandButton,
  BrandLink as BaseBrandLink,
} from '@dx-ui/osc-brand-buttons';
import { useButtonVariant } from '../../hooks/use-button-variant';

import type { Variant } from '@dx-ui/osc-brand-buttons';

const verifyButtonMeetsBackgroundContrast = (
  variant?: Variant,
  button?: HTMLAnchorElement | HTMLButtonElement
) => {
  if (button) {
    const computedBgColor =
      getComputedStyle(button).getPropertyValue('--color-background') ||
      getComputedStyle(button).getPropertyValue('--color-bg');

    const [red, green, blue] = computedBgColor.split(' ');
    const backgroundColorString = rgbToColorString({
      red: Number(red),
      green: Number(green),
      blue: Number(blue),
    });

    const buttonColorString =
      variant === 'outline'
        ? getComputedStyle(button).borderColor
        : getComputedStyle(button).backgroundColor;

    const { AALarge: meetsContrast } = meetsContrastGuidelines(
      backgroundColorString,
      buttonColorString
    );

    return meetsContrast;
  }

  return true;
};

export function BrandLink(
  props: Omit<React.ComponentProps<typeof BaseBrandLink>, 'brandComponentTheme'>
) {
  const ref = useRef<React.ElementRef<'a'>>(null);
  const { variant } = useButtonVariant(props);
  const [meetsContrast, setMeetsContrast] = useState(true);

  useEffect(() => {
    if (ref.current) {
      setMeetsContrast(verifyButtonMeetsBackgroundContrast(variant, ref.current));
    }
  }, [ref, variant]);

  return (
    <BaseBrandLink
      {...props}
      {...(!meetsContrast ? { brandComponentTheme: 'dark' } : {})}
      ref={ref}
      variant={variant}
    />
  );
}

export function BrandButton(
  props: Omit<React.ComponentProps<typeof BaseBrandButton>, 'brandComponentTheme'>
) {
  const ref = useRef<React.ElementRef<'button'>>(null);
  const { variant } = useButtonVariant(props);
  const [meetsContrast, setMeetsContrast] = useState(true);

  useEffect(() => {
    if (ref.current) {
      setMeetsContrast(verifyButtonMeetsBackgroundContrast(variant, ref.current));
    }
  }, [ref, variant]);

  return (
    <BaseBrandButton
      {...props}
      {...(!meetsContrast ? { brandComponentTheme: 'dark' } : {})}
      ref={ref}
      variant={variant}
    />
  );
}
