import { useGlobalConfig } from './use-global-config';

import type { Variant } from '@dx-ui/osc-brand-buttons';

export function useButtonVariant({ variant }: { variant?: Variant }) {
  const {
    theme: { buttonVariant },
  } = useGlobalConfig();

  return { variant: variant || buttonVariant };
}
